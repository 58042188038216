import {LitElement, html, css} from 'lit'
import {graphQL} from 'shared/graphQL'
import './locations-table.component'
import {Assets} from 'shared/assets'


export class LocationsIndex extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 29px;
      margin-bottom: 32px;
    }
    mss-label-value-box {
      max-width: 345px;
      min-width: 170px;
      flex: 1;
      margin-bottom: 18px;
    }
    
    mss-label-value-box [slot=label] {
      font-size: 18px;
    }
    mss-label-value-box [slot=value] {
      font-size: 24px;
      font-weight: normal;
    }
    
    mss-label-value-box [slot=label] svg {
      margin-right: 11px;
    }
    
    mss-label-value-box.groups {
      cursor: pointer;
    }
    mss-label-value-box.groups [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }
    
    mss-label-value-box.locations {
      cursor: default;
    }
    mss-label-value-box.locations [slot=label] {
      background-color: #438BDF;
      color: white;
    }
    mss-label-value-box.locations [slot=value] {
      background-color: #1B6BC8;
      border-left: 0;
      color: white;
    }
    
    mss-label-value-box.users {
      cursor: pointer;
    }
    mss-label-value-box.users [slot=value] {
      background-color: #EEEEEE;
      border-left: 0;
    }
  `;

  static properties = {
    data: {state: true},
    allowed: {}
  };


  constructor() {
    super()
    this.data = {}
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let query = `
      query Counts {
        groupCount(filters: {forOrganizationId: ${graphQL.organizationId()}, forAccountId: ${graphQL.accountId()}})
        locationsCount(filters: {forAccountId: ${graphQL.accountId()}})
        userCount(filter: {forOrganizationId: ${graphQL.organizationId()}, forAccountId: ${graphQL.accountId()}})
      }
    `;

    graphQL.query({
      query: query,
      success: (response) => {
        this.data = response.data
      },
      error: (message) => {
        console.log(`Error getting User and Group counts: ${message}`)
      },
    });
  }

  render() {
    return html`
      <div class="stats">
        <mss-label-value-box class="groups clickable" @click=${this.groupsClick}>
          <span slot="label">
            ${Assets.inlineSvg('small_group_icon.svg')}
            Groups
          </span>
          <span slot="value">${this.data.groupCount}</span>
        </mss-label-value-box>
        <mss-label-value-box class="locations">
          <span slot="label">
            ${Assets.inlineSvg('small_location_icon_white.svg')}
            Locations
          </span>
          <span slot="value">${this.data.locationsCount}</span>
        </mss-label-value-box>
        <mss-label-value-box class="users clickable" @click=${this.usersClick}>
          <span slot="label">
            ${Assets.inlineSvg('small_user_icon.svg')}
            Users
          </span>
          <span slot="value">${this.data.userCount}</span>
        </mss-label-value-box>
      </div>
      
      <locations-table allowed=${this.allowed}></locations-table>
    `;
  }


  groupsClick(event) {
    window.location.href = Routes.groups_path()
  }


  usersClick(event) {
    window.location.href = Routes.users_path()
  }
}
customElements.define('locations-index', LocationsIndex)


