import {LitElement, html, css} from 'lit'
import {choose} from 'lit/directives/choose'
import {Assets} from 'shared/assets'
import {graphQL} from '../shared/graphQL'
import './players-table.component'
import './players-folderless-table.component'
import './player-issue-flag.component'
import {PlayerIssueFlagsInfo} from "./player-issue-flag.component";


export class PlayersIndex extends LitElement {
  static styles = css`
    *, *:before, *:after {
      box-sizing: border-box;
    }

    .online-stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 7px;
    }
    .online-stats mss-label-value-box {
      max-width: 345px;
      min-width: 170px;
      flex: 1;
      color: white;
      margin-bottom: 18px;
      cursor: pointer;
    }
    .online-stats mss-label-value-box [slot=label] {
      font-size: 16px;
    }
    .online-stats mss-label-value-box [slot=value] {
      border-left: 0;
      font-size: 24px;
    }

    mss-label-value-box.online [slot=label] {
      background-color: #2BB487;
    }
    mss-label-value-box.online [slot=value] {
      background-color: #218b68;
    }

    mss-label-value-box.offline [slot=label] {
      background-color: #D75762;
    }
    mss-label-value-box.offline [slot=value] {
      background-color: #cb303e;
    }

    mss-label-value-box.total [slot=label] {
      background-color: #323851;
    }
    mss-label-value-box.total [slot=value] {
      background-color: #1f2231;
    }

    .issue-stats {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 30px;
      font-size: 12px;
    }
    .issue-stats mss-label-value-box {
      margin-bottom: 15px;
      height: 32px;
      margin-left: 5px;
      margin-right: 5px;
      cursor: pointer;
    }
    .issue-stats mss-label-value-box player-issue-flag {
      margin-right: 7px;
      width: 16px;
      height: 16px;
    }
    .issue-stats mss-label-value-box [slot=label] {
      font-size: 11px;
      padding-left: 5px;
      padding-right: 3px;
    }
    .issue-stats mss-label-value-box [slot=value] {
      font-size: 11px;
      padding-left: 9px;
      padding-right: 9px;
    }
  `;

  static properties = {
    player_group_id: {type: String},
    of_type: {type: String},
    of_subtype: {type: String},
    data: {state: true},
    allowed: {}
  };


  constructor() {
    super()
    this.player_group_id = null
    this.of_type = null
    this.of_subtype = null
    this.data = {}
    this.table = 'players-table'
  }


  connectedCallback() {
    super.connectedCallback()
    graphQL.ready(() => this.getData())
  }


  getData() {
    let query = `
      query {
        playerFolderStats(accountId: ${graphQL.accountId()}, organizationId: ${graphQL.organizationId()}, playerFolderId: ${this.player_group_id})
        {
          withTvIssues
          frequentReboots
          htmlInvalid
          manifestMismatch
          playersOffline
          playersOnline
          smartScanIssues
          totalPlayers
          usbStorageNotInstalled
        }
      }
    `;

    graphQL.query({
      query: query,
      success: (response) => {
        this.data = response.data.playerFolderStats
      },
      error: (message) => {
        console.log(`Error getting player folder stats: ${message}`)
      },
    });
  }


  render() {
    // render new player flags
    let flags = []
    for (const info of PlayerIssueFlagsInfo) {
      flags.push(html`
        <mss-label-value-box @click=${this.issueClick} class="clickable" title="${info.description}">
          <span slot="label">
            <player-issue-flag issue="${info.tag}"></player-issue-flag>
            ${info.name}
          </span>
          <span slot="value">${this.data[info.tagAlias]}</span>
        </mss-label-value-box>
      `)
    }
    return html`
      <div class="online-stats">
        <mss-label-value-box class="online clickable" @click=${this.statusClick}>
          <span slot="label">
            Online
          </span>
          <span slot="value">${this.data.playersOnline}</span>
        </mss-label-value-box>
        <mss-label-value-box class="offline clickable" @click=${this.statusClick}>
          <span slot="label">
            Offline
          </span>
          <span slot="value">${this.data.playersOffline}</span>
        </mss-label-value-box>
        <mss-label-value-box class="total clickable" @click=${this.statusClick}>
          <span slot="label">
            Total
          </span>
          <span slot="value">${this.data.totalPlayers}</span>
        </mss-label-value-box>
      </div>

      <div class="issue-stats">
        ${flags}
      </div>
      ${choose(this.table, [
        ['players-table', () => html`<players-table allowed=${this.allowed} player_group_id=${this.player_group_id} of_subtype=${this.of_subtype} of_type=${this.of_type}></players-table>`],
        ['players-folderless-table', () => html`<players-folderless-table allowed=${this.allowed} player_group_id=${this.player_group_id} of_subtype=${this.of_subtype} of_type=${this.of_type}></players-folderless-table>`],
      ],
      () => html`<h1>Error: please provide a valid table type.</h1>`)}
    `;
  }


  statusClick(event) {
    let classList =  event.currentTarget.classList
    let values = {
      online: classList.contains('online'),
      offline: classList.contains('offline'),
    }

    let table = this.shadowRoot.querySelector(this.table)
    if (table) {
      table.setFilterValues({'table-filter-player-on-off-line': values})
    }
  }


  issueClick(event) {
    let values = {}
    for (const info of PlayerIssueFlagsInfo) {
      values[info.tag.toUpperCase()] = false
    }

    let element = event.currentTarget.querySelector('player-issue-flag')
    if (!element) return
    let issue = element.getAttribute('issue')
    if (!issue) return
    values[issue.toUpperCase()] = true
    let table = this.shadowRoot.querySelector(this.table)
    if (!table) return
    table.setFilterValues({'table-filter-player-issue-flags': values})
  }
}
customElements.define('players-index', PlayersIndex);




