
import { html, css } from 'lit'
import { TableBase } from 'shared/tables/table-base.component'
import { graphQL } from "shared/graphQL"
import { Assets } from 'shared/assets'
import 'shared/foundation/mss-favorite-toggle.component'
import 'shared/foundation/mss-folder-icon.component'
import 'shared/tables/table-filter-player-types.component'
import 'shared/tables/table-filter-player-on-off-line.component'
import 'shared/tables/table-filter-player-issue-flags.component'
import 'shared/tables/table-filter-tags.component'
import 'shared/foundation/mss-pill.component'
import 'shared/foundation/mss-dot.component'
import { formatDateTime, timeAgo } from 'shared/dateHelpers'
import { numberToHumanSize } from 'shared/numberHelpers'
import { bulkActionClick } from 'shared/bulkActionHelpers'
import { GetPlayerIssueFlagInfo, PlayerIssueFlagsInfo } from "./player-issue-flag.component";


class PlayersTable extends TableBase {


  static styles = [
    TableBase.styles,
    css`
    .table-base-body .c-row {
      height: auto;
      min-height: 60px;
    }
    .c-column span{
      white-space: normal;
    }
    .c-column[data-type=icon] img {
      width: 36px;
    }
    .c-column[data-type=inheriting] {
      max-width: 129px;
    }
    .c-column[data-type=issue_flags] {
      max-width: 90px;
    }
    .c-column[data-type=issue_flags] player-issue-flag {
      width: 20px;
      height: 20px;
    }
    .c-column[data-type=player_status] {
      max-width: 145px;
    }
    .c-column[data-type=player_status] mss-dot.online {
      background-color: var(--success-color);
    }
    .c-column[data-type=player_status] mss-dot.offline {
      background-color: var(--danger-color);
    }
    .c-column[data-type=player_status] mss-dot.total {
      background-color: #323851;
    }
    .c-column[data-type=player_status] mss-dot.folder {
      color: #FFFFFF;
      min-width: 30px;
      width: unset;
      padding-left: 2px;
      padding-right: 2px;
      height: 30px;
      font-size: 13px;
    }

    .action svg {
      height: 27px;
      width: 27px;
      padding: 1px;
      margin-right: 10px;
    }
    .action svg path {
      transition: fill .2s;
      fill: white;
    }
    .action svg circle {
      transition: fill .2s;
      stroke: white;
    }

    .action:hover {
      color: #d6d9e0;
    }
    .action:hover svg path {
      fill: #d6d9e0;
    }
    .action:hover svg circle {
      stroke: #d6d9e0;
    }
    .table-base-body .drag-placeholder{
      background-color: #D9E6F6;
    }
    .table-base-body .drag-placeholder .dragging{
        transition: background-color 0s;
        background-color: white;
        padding-left: 10px;
        border: 1px solid $base-border-color !important;
        border-bottom: 1px solid $base-border-color;
        opacity: .5;
    }
    `,
  ];

  static properties = {
    player_group_id: { type: String },
    allowed: {}
  };

  constructor() {
    super()
    this.route = { folder: '/players?player_group_id=:id', item: '/players/:id' }
    this.player_group_id = null

    this.dataName = 'Items'
    this.columns = [
      { key: 'icon', title: '', display: 'left' },
      { key: 'bulk_action', title: '', display: 'always-left' },
      { key: 'name', title: 'Name', sortable: 'orderByName' },
      { key: 'description', title: 'Description', sortable: 'orderByDescription' },
      { key: 'serial_number', title: 'Serial Number', sortable: 'orderBySerialNumber' },
      { key: 'inheriting', title: 'Inheriting', sortable: 'orderByInheriting' },
      { key: 'schedule', title: 'Schedule', sortable: 'orderByPlayingScheduleName' },
      { key: 'site_id', title: 'Site ID', sortable: 'orderBySiteId' },
      { key: 'timezone', title: 'Time Zone', sortable: 'orderByTimezone' },
      { key: 'ip_address', title: 'IP Address', sortable: 'orderByIpAddress' },
      { key: 'tags', title: 'Tags' },
      { key: 'nepid', title: 'SAN', sortable: 'orderByNepid' },
      { key: 'media_signage_software', title: 'Player Version', sortable: 'orderBySemanticVersion' },
      { key: 'os_firmware', title: 'OS/Firmware Version', sortable: 'orderByOsFirmware' },
      { key: 'last_heartbeat_at', title: 'Last Heartbeat', sortable: 'orderByLastHeartbeatAt' },
      { key: 'storage_used', title: 'Storage', sortable: 'orderByUsedStorageBytes' },
      { key: 'issue_flags', title: 'Flags' },
      { key: 'player_status', title: 'Status', sortable: 'orderByLastHeartbeatAt' },
      { key: 'download_percentage', title: 'Download Percentage', sortable: 'orderByDownloadPercentage' },
      { key: 'favorite', title: '', display: 'always-right' },
      { key: 'actions', title: '', display: 'always-right' },
    ]

    this.orderBy = 'orderByName'   // default
    this.orderType = 'ascending'   // default

    this.paginationEnabled = true
    this.paginationOptions.dataName = this.dataName
    this.searchEnabled = true
    this.filterSections = html`
      <table-filter-player-types></table-filter-player-types>
      <table-filter-player-on-off-line></table-filter-player-on-off-line>
      <table-filter-player-issue-flags></table-filter-player-issue-flags>
      <table-filter-tags></table-filter-tags>
    `;
    this.settingsEnabled = true
    this.setBulkActionsMenu()
    // For now, using old bulk action code
  }

  setBulkActionsMenu() {
    this.bulkActionsMenu = html`
      <div class="action"
           data-url="${Routes.move_modal_folder_players_path()}"
           data-method="get"
           data-with-type="true"
           @click=${bulkActionClick}
      >
        ${Assets.inlineSvg('subnavigation/move.svg')} Move
      </div>
      <div class="action"
           data-url="${Routes.bulk_edit_players_path()}"
           data-method="visit"
           data-with-type="true"
           data-remote="false"
           @click=${bulkActionClick}
      >
        ${Assets.inlineSvg('subnavigation/edit.svg')} Update
      </div>
      <div class="action"
           data-url="${Routes.folder_players_path() + '?player_group_id=' + this.player_group_id}"
           data-method="delete"
           data-with-type="true"
           @click=${bulkActionClick}
      >
        ${Assets.inlineSvg('subnavigation/delete.svg')} Delete
      </div>
    `;
  }

  updated() {
    this.setBulkActionsMenu()
    let list = this.shadowRoot.querySelectorAll('[data-type=bulk_action] mss-checkbox')
    for (const box of list) {
      box.addEventListener('change', (event) => {
        this.bulkActionCheckboxChange(event)
      })
    }

    $(this.shadowRoot.querySelectorAll('.v-row')).draggable({
      dragStart: function (row) {
        var count, msg, partOfGroup;
        row = $(row);
        let mssCheckbox = $(row.find(".c-row .c-column[data-type='bulk_action'] span mss-checkbox"))[0]
        partOfGroup = mssCheckbox.checked
        if (!partOfGroup) {
          list.forEach(function (checkbox) {
            checkbox.checked = false
          })
        }
        $(row.find(".c-row .c-column[data-type='bulk_action'] span mss-checkbox"))[0].checked = true
        count = Array.prototype.slice.call(list).filter(function (el) { return el.checked }).length;
        msg = "And " + (count - 1) + " more selected item(s)";
        if (count > 1) {
          return row.attr("data-message", msg);
        }
      },
      drop: function (row) {
        return $(row).removeAttr("data-message");
      }
    });

    $(this.shadowRoot.querySelectorAll('.v-row')).droppable({
      drop: function (source, destination) {
        var data, destinationId, destinationType, destinationCheckbox
        destinationCheckbox = destination.find(".c-row .c-column[data-type='bulk_action'] span mss-checkbox")[0];
        destinationType = destinationCheckbox.dataset['itemType'];
        destinationId = destinationCheckbox.value;
        if (destinationType !== 'PlayerFolder') {
          gaudi.flash('You can only drag players into folders.', 'danger', 3000);
        } else {
          data = [];
          list.forEach((e) => {
            if (e.checked) {
              data.push([e.dataset['itemType'], e.value]);
            }
          });
          $.ajax({
            method: 'put',
            url: Routes.move_folder_players_path(),
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            data: {
              id: data,
              player_folder_id: destinationId
            }
          });
        }
      }
    });
  }

  getData() {
    let query = `
      query PlayerFolderContents($filters: PlayerFolderFilter!, $total_count_filters: PlayerFolderFilter!)
      {
        playerFolderContentsCount(filters: $total_count_filters)
        playerFolderContents(filters: $filters) {
          recordType: __typename

          ... on Player {
            account {
              id
            }
            organization {
              id
            }
            id
            name
            issueFlags
            description
            serialNumber
            playingSchedule {
              name
            }
            siteId
            timezone
            ipAddress
            tags {
              name
            }
            inheritedTags {
              name
            }
            parentType {
              id
              name
              image
            }
            online
            nepid
            semanticVersion
            osFirmware
            lastHeartbeatAt
            usedStorageBytes
            totalStorageBytes
            downloadPercentage
            playerSetting {
              id
              inheriting
            }
            favorites {
              id
            }
          }

          ... on PlayerFolder {
            id
            name
            calculatedSchedule {
              name
            }
            tags {
              name
            }
            inheritedTags {
              name
            }
            playerSetting {
              id
              inheriting
              schedule {
                name
              }
            }
            stats {
              displayConnected
              frequentReboots
              htmlInvalid
              manifestMismatch
              playersOffline
              playersOnline
              smartScanIssues
              totalPlayers
              usbStorageNotInstalled
            }
            favorites {
              id
            }
          }
        }
      }
    `;

    let variables = {
      filters: {
        pager: {
          pageSize: this.pageSize,
          page: this.page - 1,
        },
      },
      total_count_filters: {
      },
    };

    if (this.orderBy && this.orderType) {
      let val = {}
      val[this.orderBy] = this.orderType === 'ascending' ? 'ASC' : 'DESC'
      variables.filters.sort = val
    }
    if (this.searchValue) {
      variables.filters['recordLike'] = this.searchValue
    }

    let filtering = {}

    if (graphQL.accountId()) filtering.accountId = graphQL.accountId()
    if (graphQL.organizationId()) filtering.organizationId = graphQL.organizationId()

    if (this.player_group_id) filtering.playerFolderId = this.player_group_id

    let values
    if (this.filterValues) {
      if ((values = this.filterValues["table-filter-player-types"])) {
        filtering.ofAnyType = values
      }

      if ((values = this.filterValues["table-filter-player-on-off-line"])) {
        let online = values.online
        let offline = values.offline
        if (online && !offline) {
          filtering.online = true
        } else if (!online && offline) {
          filtering.online = false
        }
      }

      if ((values = this.filterValues["table-filter-player-issue-flags"])) {
        filtering.hasAnyIssueFlags = values
      }

      if ((values = this.filterValues["table-filter-tags"])) {
        filtering.hasAnyTags = values
      }
    }

    // Put filtering in both 'filters' and 'total_count_filters'
    for (const filter in filtering) {
      variables.filters[filter] = filtering[filter]
      variables.total_count_filters[filter] = filtering[filter]
    }

    graphQL.query({
      query: query,
      variables: variables,
      success: (response) => {
        this.totalCount = response.data.playerFolderContentsCount
        this.data = response.data.playerFolderContents
      },
      error: (message) => {
        this.data = []
        console.log('GraphQL Errors: ', message)
      },
    });
  }


  cellValue(colKey, item) {
    let value = html``;

    if (colKey === 'bulk_action') {
      if (this.rowIsDisabled(item)) {
        value = html``
      } else {
        let itemType = item.recordType || ''
        value = html`<mss-checkbox data-item-type=${itemType}
                                   name="${itemType === 'Player' ? 'Player' : 'PlayerFolder'}"
                                   value="${item.id}"></mss-checkbox>`
      }

    } else if (colKey === 'favorite') {
      if (this.rowIsDisabled(item)) {
        value = html``
      } else {
        let itemType = item.recordType || ''
        let itemId = item.id || ''
        value = {
          data: html`
          <mss-favorite-toggle .itemType=${itemType} .itemId=${itemId}
             ?checked=${item.favorites && item.favorites.length > 0}>
          </mss-favorite-toggle>`, title: ''
        }
      }

    } else if (colKey === 'icon') {
      if (item.recordType === 'Player' && item.parentType && item.parentType.image) {
        if (this.rowIsDisabled(item)) {
          value = html`deleting`
        } else {
          value = html`<img src="${item.parentType.image}"></img>`
        }
      } else if (item.recordType === 'PlayerFolder') {
        value = html`<mss-folder-icon count="${item.stats.totalPlayers}" title='${item.stats.totalPlayers} players'></mss-folder-icon>`
      }

    } else if (colKey === 'name') {
      value = item?.name

    } else if (colKey === 'description') {
      value = item?.description

    } else if (colKey === 'serial_number') {
      value = item?.serialNumber

    } else if (colKey === 'inheriting') {
      value = `${item?.playerSetting?.inheriting ? 'Yes' : 'No'}`

    } else if (colKey === 'schedule') {
      if (item.recordType === 'Player') {
        value = (item?.playingSchedule && item?.playingSchedule?.name) || ''
      }
      else if (item.recordType === 'PlayerFolder') {
        if (item?.playerSetting?.inheriting) {
          value = item?.calculatedSchedule?.name;
        } else {
          value = item?.playerSetting?.schedule?.name;
        }
      }

    } else if (colKey === 'issue_flags') {
      if (item.recordType === 'Player') {
        let info = GetPlayerIssueFlagInfo(item.issueFlags[0])
        if (info) {
          value = html`<player-issue-flag issue="${info.tag}"
                  title="${info.description}"></player-issue-flag>`
        }
      } else if (item.recordType === 'PlayerFolder') {
        for (const info of PlayerIssueFlagsInfo) {
          if (item.stats[info.tagAlias] > 0) {
            value = html`<player-issue-flag issue="${info.tag}"
                    title="${info.description}"></player-issue-flag>`
            break
          }
        }
      }
    } else if (colKey === 'player_status') {
      if (item.recordType === 'Player') {
        value = {
          data: html`<mss-dot class="${item.online ? 'online' : 'offline'}"></mss-dot>&nbsp;&nbsp;
                     ${timeAgo(item.lastHeartbeatAt, true)}`, title: `${item.online ? 'online' : 'offline'} ${timeAgo(item.lastHeartbeatAt, true)}`
        }

      } else if (item.recordType === 'PlayerFolder') {
        value = {
          data: html`
          <mss-dot class="folder online">${item?.stats?.playersOnline}</mss-dot>
          <mss-dot class="folder offline">${item?.stats?.playersOffline}</mss-dot>
          <mss-dot class="folder total">${item?.stats?.totalPlayers}</mss-dot>
        `, title: `Online: ${item?.stats?.playersOnline}, Offline: ${item?.stats?.playersOffline}, Total: ${item?.stats?.totalPlayers}`
        };
      }

    } else if (colKey === 'download_percentage') {
      if (item.downloadPercentage === 0.0 || item.downloadPercentage) {
        value = `${Math.floor(item.downloadPercentage * 100)}%`
      } else {
        value = ''
      }

    } else if (colKey === 'site_id') {
      value = item?.siteId

    } else if (colKey === 'timezone') {
      value = item?.timezone

    } else if (colKey === 'ip_address') {
      value = item?.ipAddress

    } else if (colKey === 'tags') {
      let list = []
      let tagNames = []
      if (item.inheritedTags) {
        for (const tag of item.inheritedTags) {
          list.push(html`<focus-color-pill>${tag.name}</mss-pill>`)
          tagNames.push(tag.name)
        }
      }
      if (item.tags) {
        for (const tag of item.tags) {
          list.push(html`<base-color-pill>${tag.name}</mss-pill>`)
          tagNames.push(tag.name)
        }
      }
      value = { data: list, title: tagNames.join(', ') }

    } else if (colKey === 'nepid') {
      value = item?.nepid

    } else if (colKey === 'media_signage_software') {
      value = item?.semanticVersion

    } else if (colKey === 'os_firmware') {
      value = item?.osFirmware

    } else if (colKey === 'last_heartbeat_at') {
      value = formatDateTime(item?.lastHeartbeatAt)

    } else if (colKey === 'storage_used') {
      if (item.usedStorageBytes || item.totalStorageBytes) {
        value = `${numberToHumanSize(item?.usedStorageBytes, 3, true)} / ${numberToHumanSize(item?.totalStorageBytes, 3, true)}`
      }

    } else if (colKey === 'actions') {
      let settingId = '0'
      if (item.playerSetting && item.playerSetting.id) {
        settingId = item?.playerSetting?.id
      }
      if (item.recordType === 'Player') {
        value = [
          {
            title: 'View',
            url: Routes.player_path(item.id),
            httpMethod: 'GET',
            remote: false,
            protect: 'show'
          },
          {
            title: 'Edit',
            url: Routes.edit_player_path(item.id),
            httpMethod: 'GET',
            remote: false,
            protect: 'edit'
          },
          {
            title: 'Delete',
            url: Routes.player_path(item.id),
            httpMethod: 'DELETE',
            remote: true,
            confirm: `Are you sure you want to delete ${item.name || 'this record'}?`,
            protect: 'destroy'
          },
          {
            title: 'Settings',
            url: Routes.edit_player_setting_path(settingId),
            httpMethod: 'GET',
            remote: false,
            protect: 'update'
          },
        ]
      } else if (item.recordType === 'PlayerFolder') {
        value = [
          {
            title: 'Open',
            url: Routes.players_path({ player_group_id: item.id }),
            httpMethod: 'GET',
            remote: false,
            protect: 'show'
          },
          {
            title: 'Edit',
            url: Routes.edit_player_folder_path(item.id),
            httpMethod: 'GET',
            remote: true,
            protect: 'update'
          },
          {
            title: 'Delete',
            url: Routes.player_folder_path(item.id),
            httpMethod: 'DELETE',
            confirm: `Are you sure you want to delete ${item.name || 'this folder'}?`,
            remote: false,
            protect: 'destroy'
          },
          {
            title: 'Settings',
            url: Routes.edit_player_setting_path(settingId),
            httpMethod: 'GET',
            remote: false,
            protect: 'update'
          },
        ]
      }
    }
    return value;
  }


  rowIsDisabled(item) {
    return item['deleting']
  }


  rowClass(item) {
    if (this.rowIsDisabled(item)) {
      return 'disable-row'
    }
    return ''
  }



  cellClick(key, item, event) {
    if (key !== 'bulk_action' && key !== 'favorite' && key !== 'actions') {
      if (item.recordType === 'Player') {
        window.location.href = Routes.player_path(item.id)

      } else if (item.recordType === 'PlayerFolder') {
        window.location.href = Routes.players_path({ player_group_id: item.id })
      }
    }
  }


  defaultTableSettings() {
    return {
      desktop: {
        visibleColumns: [
          'icon',
          'name',
          'description',
          'inheriting',
          'schedule',
          'issue_flags',
          'player_status',
        ]
      },
      tablet: {
        visibleColumns: [
          'icon',
          'name',
          'schedule',
          'issue_flags',
          'player_status',
        ]
      },
      mobile: {
        visibleColumns: [
          'icon',
          'name',
          'player_status',
        ]
      },
    }
  }
}
customElements.define('players-table', PlayersTable)
